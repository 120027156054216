export const darkTheme = {
    bg:"#ecedee",
    bgLight: "#ecedee",
    primary:"#273142",
    text_primary:"#252525",
    text_secondary:"#404040",
    card:"#d5daec",
    card_light: '#FFFFFF',
    button:"#1d2129",
    white:"#FFFFFF",
    black:"#000000",
}

export const lightTheme = {
    bg:"#FFFFFF",
    bgLight: "#f0f0f0",
    primary:"#be1adb",
    text_primary:"#111111",
    text_secondary:"#48494a",
    card:"#FFFFFF",
    button:"#5c5b5b",
}