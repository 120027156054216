import pythonIcon from '../asset/icons/icons8-python-48.png';
import htmlIcon from '../asset/icons/icons8-html-48.png';
import cssIcon from '../asset/icons/icons8-css-48.png';
import cIcon from '../asset/icons/icons8-c-48.png';
import cplusplusIcon from '../asset/icons/icons8-c++-48.png';
import javascriptIcon from '../asset/icons/icons8-javascript-48.png';
import reactjsIcon from '../asset/icons/icons8-reactjs-48.png';
import opencvIcon from '../asset/icons/icons8-opencv-48.png';
import tensorflowIcon from '../asset/icons/icons8-tensorflow-48.png';
import pandasIcon from '../asset/icons/icons8-pandas-48.png';
import scikitlearnIcon from '../asset/icons/scikitlearn.ico';
import qtIcon from '../asset/icons/qt-48.png';
import djangoIcon from '../asset/icons/icons8-django-48.png';
import ultralyticsIcon from '../asset/icons/ultralytics.ico';
import arduinoIcon from '../asset/icons/icons8-arduino-48.png';
import atmelavrIcon from '../asset/icons/icons8-c-48.png';
import vscodeIcon from '../asset/icons/icons8-vscode-48.png';
import colabIcon from '../asset/icons/icons8-google-colab-48.png';
import githubIcon from '../asset/icons/icons8-github-48.png';
import arduinoideIcon from '../asset/icons/icons8-arduino-48.png';
import winavrIcon from '../asset/icons/winavr-48.png';
import proteusIcon from '../asset/icons/proteus-48.png';
import freecadIcon from '../asset/icons/freecad-48.png';
import kicadIcon from '../asset/icons/kicad-48.png';
import eagleIcon from '../asset/icons/eagle.ico';
import msofficeIcon from '../asset/icons/icons8-office-48.png';
import matlabIcon from '../asset/icons/icons8-matlab-48.png';
import canvaIcon from '../asset/icons/icons8-canva-48.png';
import upiIcon from '../asset/icons/LOGO-UPI.jpg';
import smkIcon from '../asset/icons/smkn12bdg.png';


export const Bio = {
    name: "Rafli Zaki Rabbani",
    roles: [
      "a Programmer",
      "an Engineering Student",
    ],
    description:
      "Motivated third-year undergraduate student that possess a solid foundation and interest in machine learning, robotics and electrical engineering, with a demonstrated ability to apply theoretical knowledge in practical settings. Eager to leverage my skills and experiences to contribute to a dynamic team and gain further practical insights in the field of automation.",
    github: "https://github.com/sobranus",
    resume: "https://drive.google.com/file/d/1mMcWiaTJfICEC77a9KYEP96wM2l24qwJ/view?usp=sharing",
    linkedin: "https://www.linkedin.com/in/raflizaki/",
    twitter: "",
    insta: "https://www.instagram.com/raflizaki/",
    facebook: "",
  };



  export const skills = [
    {
      title: "Language",
      skills: [
        {
          name: "Python",
          image: pythonIcon,
        },
        {
          name: "HTML",
          image: htmlIcon,
        },
        {
          name: "CSS",
          image: cssIcon,
        },
        {
          name: "C",
          image: cIcon,
        },
        {
          name: "C++",
          image: cplusplusIcon,
        },
        {
          name: "JavaScript",
          image: javascriptIcon,
        },
      ],
    },
    {
      title: "Frameworks",
      skills: [
        {
          name: "React JS",
          image: reactjsIcon,
        },
        {
          name: "OpenCV",
          image: opencvIcon,
        },
        {
          name: "TensorFlow",
          image: tensorflowIcon,
        },
        {
          name: "Pandas",
          image: pandasIcon,
        },
        {
          name: "scikit-learn",
          image: scikitlearnIcon,
        },
        {
          name: "Qt",
          image: qtIcon,
        },
        {
          name: "Ultralytics",
          image: ultralyticsIcon,
        },
        {
          name: "Django",
          image: djangoIcon,
        },
        {
          name: "Arduino",
          image: arduinoIcon,
        },
        {
          name: "Atmel AVR",
          image: atmelavrIcon,
        },
      ],
    },
    {
      title: "Platform/Tools",
      skills: [
        {
          name: "VS Code",
          image: vscodeIcon,
        },
        {
          name: "CoLab",
          image: colabIcon,
        },
        {
          name: "GitHub",
          image: githubIcon,
        },
        {
          name: "Arduino IDE",
          image: arduinoideIcon,
        },
        {
          name: "WinAVR",
          image: winavrIcon,
        },
        {
          name: "Proteus",
          image: proteusIcon,
        },
        {
          name: "FreeCAD",
          image: freecadIcon,
        },
        {
          name: "KiCAD",
          image: kicadIcon,
        },
        {
          name: "EAGLE",
          image: eagleIcon,
        },
        {
          name: "MS Office",
          image: msofficeIcon,
        },
        {
          name: "MATLAB",
          image: matlabIcon,
        },
        {
          name: "Canva",
          image: canvaIcon,
        },
      ],
    },
    {
      title: "Others",
      skills: [
        {
          name: "Electrical Assembly",
          image:  "a",
        },
        {
          name: "Advance Level English",
          image:  "a",
        },
        {
          name: "Presentation",
          image:  "a",
        },
        {
          name: "Leadership",
          image:  "a",
        },
        {
          name: "Creativity",
          image:  "a",
        },
        {
          name: "Adaptability",
          image:  "a",
        },
        {
          name: "Critical Thinking",
          image:  "a",
        },
        {
          name: "Volleyball",
          image:  "a",
        },
      ],
    },
  ];
  




  export const experiences = [
    {
      id: 1,
      img: upiIcon,
      role: "Assistive Learning Technology Development",
      company: "As System Developer and Research Assistant",
      date: "May 2024 - Present",
      desc: `
        I currently working on developing an assistive technology for teachers on automating teaching methods. I responsible for building the system and developing a computer vision application of hand tracking and gesture recognition in Python using OpenCV. Training and integrating the data using framework TensorFlow and Pandas then testing the application on PyQt5 GUI.
        `,
      skills: [
        "Python",
        "OpenCV",
        "TensorFlow",
        "Pandas",
        "PyQt5",
      ],
      doc: "",
    },
    {
      id: 1,
      img: "https://raw.githubusercontent.com/sobranus/Document/main/gn100sd.png",
      role: "Gerakan Nasional 1000 Startup Digital",
      company: "Direktorat Jenderal Aplikasi Informatika Kementerian Komunikasi dan Informatika",
      date: "February 2024 - Present",
      desc: `
        Assigned as a role: Hacker, as the CTO of the startup team Future Start. Developing the prototype and minimum marketable product’s technology, to do the tech comparative research, and build a curriculum of basic deep learning course.
        `,
      skills: [
        "ReactJS",
        "HTML",
        "CSS",
        "JavaScript",
        "Softskills"
      ],
      doc: [
        "https://raw.githubusercontent.com/sobranus/Document/main/certificate-gn1000sd-hack4id-day2.png",
        "https://raw.githubusercontent.com/sobranus/Document/main/certificate-gn1000sd-ignition-ai.png",
        "https://raw.githubusercontent.com/sobranus/Document/main/certificate-gn1000sd-modul-hacker.png"
      ],
    },
  ];
  




  export const education = [
    {
      id: 0,
      img: upiIcon,
      school: "Universitas Pendidikan Indonesia",
      date: "2021 - 2025",
      grade: "3.75/4.00 GPA",
      desc: "I am currently pursuing a Bachelor's degree in Industrial Automation and Robotics Engineering Education at Universitas Pendidikan Indonesia, Bandung. I have completed 4 semesters and have a CGPA of 8.71. I have taken courses in Data Structures, Algorithms, Object-Oriented Programming, Database Management Systems, Operating Systems, and Computer Networks, among others. I am also a member of the Google Developers Student Club (GDSC) at KIIT, where I am learning and working on exciting projects with a team of talented developers.",
      degree: "Facculty of electrical engineering education, Industrial Automation and Robotics Engineering Education",
    },
    {
      id: 1,
      img: smkIcon,
      school: "SMK Negeri 12 Bandung",
      date: "2018 - 2021",
      desc: "I completed my vocational school education at SMK Negeri 12 Bandung, Kota Bandung, where I studied Aircraft Electricity.",
      degree: "Aircraft Electricity",
    },
  ];
  




  export const projects = [
    {
      id: 0,
      title: "Line Follower Robot for Line Track with Obstacles",
      date: "November 2023 - January 2024",
      description:`
        Designed a functional line follower robot to follow line tracks with obstacles like dotted line, inversed tracks, checkpoint, and color changes.
        Most of the work done manually, starting from the schematic designing, PCB designing, assemblying the electrical and mechanical part, and 
        creating the logic code.
      `,
      image:
        "https://raw.githubusercontent.com/sobranus/Line-Follower-Robot-for-Line-Track-with-Obstacles/main/line-follower.jpeg",
      tags: [
        "Atmel AVR",
        "Microcontroller",
        "KiCAD",
        "C",
        "WinAVR",
        "Electrical Assembly"
      ],
      category: "hardware",
      github: "https://github.com/sobranus/Line-Follower-Robot-for-Line-Track-with-Obstacles",
      webapp: "https://github.com/sobranus/Line-Follower-Robot-for-Line-Track-with-Obstacles",
    },
    {
      id: 1,
      title: "Automatic Fire Fighter Servo Robot",
      date: "May 2024 - June 2024",
      description:`
        A design and simulation of a robot that automatically scan the FOV area with infrared sensor that moved by the servo servo, aiming to light source as fire. 
        The fire then will be aimed using vertical servo and then extinguished using water pump spray after the center sensor detected the fire.
        `,
      image:
        "https://raw.githubusercontent.com/sobranus/Automatic-Fire-Fighter-Servo-Robot/main/screenshot.png",
      tags: [
        "FreeCAD",
        "SimulIDE",
        "KiCAD",
        "WinAVR",
        "Microcontroller",
        "Atmel AVR",
        "C"
      ],
      category: "hardware",
      github: "https://github.com/sobranus/Line-Follower-Robot-for-Line-Track-with-Obstacles",
      webapp: "https://drive.google.com/file/d/1AiNlVwfQ0R5cZq-oXiHKQCO7ajxrwJbo/view?usp=sharing",
    },
    {
      id: 2,
      title: "Temperature Detector with LCD Display and Fire Alarm System",
      date: "2023",
      description:`
        A Temperature detector and fire alarm system using Arduino UNO R3 as microcontroller and LCD 16x2 as display.
      `,
      image:
        "https://raw.githubusercontent.com/sobranus/Document/main/screenshot-project-alarm-sistem.png",
      tags: [
        "Arduino",
        "Arduino IDE",
        "Electrical Assembly",
        "Proteus",
        "Microcontroller"
      ],
      category: "hardware",
      github: "https://github.com/sobranus/Fire-Alarm-System",
      webapp: "https://github.com/sobranus/Fire-Alarm-System",
      member: [
        {
          name: "Rafli Zaki Rabbani",
          img: "https://raw.githubusercontent.com/sobranus/Document/main/photo-me.jpg",
          linkedin: "https://www.linkedin.com/in/raflizaki/",
          github: "https://github.com/sobranus/",
        },
        {
          name: "Wira Hadi Kusuma",
          img: "https://media.licdn.com/dms/image/D5603AQGMvxuglXgGKw/profile-displayphoto-shrink_800_800/0/1690519096353?e=1723680000&v=beta&t=MRUqNhlYlaS0ntJL6401v98zrVp5twJObS0Llmsa-sQ",
          linkedin: "https://www.linkedin.com/in/wira-hadi-kusuma-352273221/",
          github: "",
        },
      ],
    },
    {
      id: 3,
      title: "Movie Recommendation System",
      date: "2023",
      description:`
        Movie recommendation system based on another movie or preferences such as genres, release date, types, ratings. 
        Using methods like tokenization, cosine-similarity, min-max scaler, and count vectorizer.
      `,
      image:
        "https://raw.githubusercontent.com/sobranus/movie-recommendation-system/main/Movie-Recommendation-System/movie-recommendation-system.png",
      tags: [
        "Python",
        "scikit-learn",
        "Pandas",
        "Tkinter"
      ],
      category: "software",
      github: "https://github.com/sobranus/movie-recommendation-system",
      webapp: "https://github.com/sobranus/movie-recommendation-system",
    },
    {
      id: 4,
      title: "Assistive Learning: Virtual Quiz",
      date: "May 2024 - Present (Under Development)",
      description:`
        A Virtual Quiz App that using Hand Gesture Detection to answer the question, with quiz edit input, scoring system, quiz navigation, and
        quiz data handling.
      `,
      image:
        "https://raw.githubusercontent.com/sobranus/Virtual-Quiz-and-Presentation/main/screenshots/Screenshot%202024-08-03%20233022.png",
      tags: [
        "Python",
        "OpenCV",
        "TensorFlow",
        "PyQt5",
      ],
      category: "software",
      github: "https://github.com/sobranus/Virtual-Quiz-and-Presentation",
      webapp: "https://github.com/sobranus/Virtual-Quiz-and-Presentation",
    },
    {
      id: 5,
      title: "Assistive Learning: Virtual Presentation",
      date: "May 2024 - Present (Under Development)",
      description:`
        A Virtual Presentation Tools App that help presenter present their presentation without the need of an operator. The presenter
        can move the cursor that track the tip of index finger and able to do right or left click in "mouse mode". There is
        also a "key mode" to trigger the press of various keys such as arrows key, esc button, etc. that often used during presentation
        by the operator, using hand gestures detection. User can switch in between two modes easily by spesific gesture.
      `,
      image:
        "https://raw.githubusercontent.com/sobranus/Virtual-Quiz-and-Presentation/main/screenshots/Screenshot%202024-08-03%20233116.png",
      tags: [
        "Python",
        "OpenCV",
        "TensorFlow",
        "PyQt5",
      ],
      category: "software",
      github: "https://github.com/sobranus/Virtual-Quiz-and-Presentation",
      webapp: "https://github.com/sobranus/Virtual-Quiz-and-Presentation",
    },
    {
      id: 6,
      title: "ScentSync",
      date: "May 2024 - Present (Under Development)",
      description:`
        A fragrance recommendation and personalization system and E-store website project. Aiming to recommending fragrances based on usability 
        (time, weather, climate, places, etc) and appearances such as clothes color, situational styling, etc.
      `,
      image:
        "https://raw.githubusercontent.com/sobranus/Scent-Sync/main/screenshot-scent-sync.png",
      tags: [
        "HTML",
        "CSS",
        "JavaScript",
        "React JS",
        "Python",
        "Django"
      ],
      category: "software",
      github: "",
      webapp: "",
    },
    {
      id: 7,
      title: "2048 Game Clone with Python",
      date: "2022",
      description:`
        Clone of a game called \'2048\'. Great project to learn about numpy array, matrix manipulation, positioning and calculation.
      `,
      image:
        "https://raw.githubusercontent.com/sobranus/2048-game/main/2048-screenshot.png",
      tags: [
        "Python",
        "PyGame",
        "NumPy",
      ],
      category: "software",
      github: "https://github.com/sobranus/2048-game",
      webapp: "https://github.com/sobranus/2048-game",
      member: [
        {
          name: "Rafli Zaki Rabbani",
          img: "https://raw.githubusercontent.com/sobranus/Document/main/photo-me.jpg",
          linkedin: "https://www.linkedin.com/in/raflizaki/",
          github: "https://github.com/sobranus/",
        },
        {
          name: "Inayah Nabila",
          img: "https://media.licdn.com/dms/image/C4D03AQHbw6lTHLA8Tg/profile-displayphoto-shrink_800_800/0/1632449112399?e=1723680000&v=beta&t=74x2qqYRK3qYPeXGN50eyOtjUmc4ho_mXtUK39nJetM",
          linkedin: "https://www.linkedin.com/in/inayah-nabila-22a445221/",
          github: "",
        },
      ],
    },
    {
      id: 8,
      title: "R-OHM: Resistor Color Band Value Guessing Game",
      date: "2022",
      description:`
        A game that can train your resistor color band readings.
      `,
      image:
        "https://raw.githubusercontent.com/sobranus/R-OHM/main/screenshot-R-OHM.png",
      tags: [
        "Python",
        "PyGame",
      ],
      category: "software",
      github: "https://github.com/sobranus/R-OHM",
      webapp: "https://github.com/sobranus/R-OHM",
    },
  ];
  



  
  export const TimeLineData = [
    { year: 2017, text: "" },
    { year: 2018, text: "" },
    { year: 2019, text: "" },
    { year: 2020, text: "" },
    { year: 2021, text: "" },
    { year: 2022, text: "" },
    { year: 2023, text: "" },
    { year: 2024, text: "" },
  ];
  